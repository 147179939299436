import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { UploadFile } from "./common";


@CwtModel({ name: 'money-holder' })
@CwtCrudContract({ path: 'money-holder', searchMapper: (s) => ({ 'name': { regexp: s } })})

export class MoneyHolder extends CwtApiEntity {
    @CwtProp() name: string;
    @CwtProp({ ref: UploadFile }) logo: UploadFile;
}