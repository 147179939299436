import { BooleanInput } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Directive, ElementRef, HostListener, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { isset } from '@cawita/core-front';
import { Formatting } from '@server-shared';
import { SimpleValueAccessor, provideValueAccessor } from '@shared/widgets/features/common/simple-value-accessor/simple-value-accessor.directive';

@Directive({
  selector: 'input[cwt-currency-input]',
  standalone: true,
  providers: [provideValueAccessor(CurrencyInputComponent)],
})
export class CurrencyInputComponent extends SimpleValueAccessor<string> {

  public formattedValue: string;
  public inFocus = false;

  constructor(private elem: ElementRef) { super(); }

  writeValue(obj: number): void {
    if (isset(obj)) {
      this.value = Formatting.currency.round(obj);
      this.formattedValue = Formatting.currency.format(obj);
    } else {
      this.value = undefined;
      this.formattedValue = undefined;
    }
    this._updateViewValue();
    this.cdRef.markForCheck();
  }


  setValue(value: string) {
    const newValue = Formatting.currency.parse(value);
    this.formattedValue = Formatting.currency.format(newValue);
    this.value = newValue;
    this.onChange(newValue);
    this.onTouched();
    this._updateViewValue();
    this.cdRef.markForCheck();
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: any) {
    this.setValue(value);
  }

  @HostListener('focus')
  onFocus() {
    this.inFocus = true;
    this._updateViewValue();
    this.cdRef.markForCheck();
  }

  @HostListener('blur')
  onBlur() {
    this.inFocus = false;
    this._updateViewValue();
    this.onTouched();
    this.cdRef.markForCheck();
  }

  private _updateViewValue() {
    if (this.inFocus) {
      this.elem.nativeElement.value = this.value ?? '';
    } else {
      this.elem.nativeElement.value = this.formattedValue ?? '';
    }
  }
}
