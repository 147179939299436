import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp, CwtSubCrudContract } from "@cawita/core-front/api";
import { Company } from "../company";
import { Invoice } from "./invoice.model";
import { Quote } from "./quote.model";

export enum ObservationType {
    Quote = 'quote',
    Invoice = 'invoice'
}

@CwtModel({ name: 'observation' })
@CwtCrudContract({ path: 'company/:companyId/observation' })
@CwtSubCrudContract({ parent: Quote, parentPath: 'company/:companyId/quote', subPath: 'observation' })
@CwtSubCrudContract({ parent: Invoice, parentPath: 'company/:companyId/invoice', subPath: 'observation' })
export class Observation extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp() title: string;
    @CwtProp() content: string;
    @CwtProp() type: ObservationType;
    @CwtProp() order: number;
    @CwtProp({ ref: Quote }) quote: Quote;
    @CwtProp({ ref: Invoice }) invoice: Invoice;
}