import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { hasRequiredPermission } from '@cawita/core-front/auth';
import { CwtDirectiveView } from '@cawita/core-front/common';
import { Subscription } from 'rxjs';
import { CompanyStore } from '../company.store';

/**
 * Displays the element only if the logged user as specified persmissions
 * Input format: 'permission' or ['perm1', 'perm2']
 */
@Directive({ selector: '[cwtIfAnyCompanyPermission]' })
export class IfAnyCompanyPermissionDirective implements OnDestroy {
    private _sub: Subscription;
    private _view = new CwtDirectiveView(this.vcRef, this.tRef);
    private _elseView = new CwtDirectiveView(this.vcRef, null);

    @Input('cwtIfAnyCompanyPermission') set permissions(permissions: string | string[]) {
        if (this._sub) this._unsubscribe();
        this._sub = this.companyStore.stableState$.subscribe((state) => {
            const hasPermission = state.accesses?.length && state.accesses.some(a => a.isOwner === true || hasRequiredPermission(a.permissions, permissions))
            this._view.ensureState(hasPermission);
            this._elseView.ensureState(!hasPermission)
        });
    }

    @Input('cwtIfAnyCompanyPermissionElse') set elseTemplate(v: TemplateRef<any>) {
        this._elseView.setTemplate(v);
    }

    constructor(
        private tRef: TemplateRef<any>,
        private vcRef: ViewContainerRef,
        private companyStore: CompanyStore
    ) { }

    ngOnDestroy(): void {
        this._unsubscribe();
    }

    private _unsubscribe() {
        this._sub?.unsubscribe();
        this._sub = null;
    }
}
