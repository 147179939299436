import { Observable, scan, shareReplay, startWith, switchMap } from "rxjs";

export type ObservableArrayAction = {
    action: 'push' | 'remove';
    payload: any;
};


export function observeArray<T>(
    seed: Observable<T[]>,
    mutator: Observable<ObservableArrayAction>
) {
    return seed.pipe(
        switchMap(seedValue => {
            return mutator.pipe(
                scan<ObservableArrayAction, T[]>((arr, value) => {
                    if (value.action === 'push') return [...arr, value.payload];
                    if (value.action === 'remove') return arr.filter(a => value.payload(a));
                    return arr;
                }, seedValue),
                startWith(seedValue)
            );
        }),
        shareReplay(1)
    );
}