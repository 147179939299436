const { addYears, addMonths, addDays, subYears, subMonths, subDays } = require('date-fns');
const { format, utcToZonedTime } = require('date-fns-tz');
const { fr: localeFR } = require('date-fns/locale');
const { TemplateConfiguration, getTemplateVariables } = require('./template-utils');
const { isEmptyOrNotSet } = require('@hints/utils/data');

const config = new TemplateConfiguration({
    recurringInvoice: {
        constraints: ['title', 'description'],
        variables: {
            year: { type: 'string', label: 'recurring-variables.year' },
            previousYear: { type: 'string', label: 'recurring-variables.previousYear' },
            nextYear: { type: 'string', label: 'recurring-variables.nextYear' },
            month: { type: 'string', label: 'recurring-variables.month' },
            previousMonth: { type: 'string', label: 'recurring-variables.previousMonth' },
            nextMonth: { type: 'string', label: 'recurring-variables.nextMonth' },
            day: { type: 'string', label: 'recurring-variables.day' },
            previousDay: { type: 'string', label: 'recurring-variables.previousDay' },
            nextDay: { type: 'string', label: 'recurring-variables.nextDay' },
            monthInLetter: { type: 'string', label: 'recurring-variables.monthInLetter' },
            previousMonthInLetter: { type: 'string', label: 'recurring-variables.previousMonthInLetter' },
            nextMonthInLetter: { type: 'string', label: 'recurring-variables.nextMonthInLetter' },
            dayInLetter: { type: 'string', label: 'recurring-variables.dayInLetter' },
            previousDayInLetter: { type: 'string', label: 'recurring-variables.previousDayInLetter' },
            nextDayInLetter: { type: 'string', label: 'recurring-variables.nextDayInLetter' },
        }
    }
}, {
});

const invoiceVarFields = ['title'];
const itemVarFields = ['title', 'description'];
const observationVarFields = ['content'];

function _formatDynamicFieldsFromObj(useCase, variables, obj = {}, varz) { 
    if (isEmptyOrNotSet(variables)) return;
    variables.forEach((v) => {
        if (isEmptyOrNotSet(obj[v])) return;
        const { content } = config.parseContent(useCase, obj[v], varz);
        obj[v] = content;
    });
}
function _getDynamicFieldsFromObj(variables, obj = {}) {
    if (isEmptyOrNotSet(variables)) return;
    return variables.map(v => obj[v]).filter(v => !isEmptyOrNotSet(v));
}

module.exports = {
    config,
    formatRecurringInvoiceSchema: (useCase, invoiceSchema, varz) => {
        _formatDynamicFieldsFromObj(useCase, invoiceVarFields, invoiceSchema, varz);
        for (const item of invoiceSchema.invoiceItems || []) _formatDynamicFieldsFromObj(useCase, itemVarFields, item, varz);
        for (const item of invoiceSchema.observations || []) _formatDynamicFieldsFromObj(useCase, observationVarFields, item, varz);
    },
    parseRecurringInvoiceSchemaVariables: (invoiceSchema) => {
        const valuesToCheck = [..._getDynamicFieldsFromObj(invoiceVarFields, invoiceSchema)];
        for (const item of invoiceSchema.invoiceItems || []) valuesToCheck.push(..._getDynamicFieldsFromObj(itemVarFields, item));
        for (const item of invoiceSchema.observations || []) valuesToCheck.push(..._getDynamicFieldsFromObj(observationVarFields, item));
        return valuesToCheck.reduce((arr, value) => arr.concat(getTemplateVariables(value)), []);
    },
    getRecurringInvoiceVariables: (date = new Date()) => {
        date = utcToZonedTime(date, 'Europe/Paris');
        const previousMonth = utcToZonedTime(subMonths(date, 1), 'Europe/Paris');
        const nextMonth = utcToZonedTime(addMonths(date, 1), 'Europe/Paris');
        const previousDay = utcToZonedTime(subDays(date, 1), 'Europe/Paris');
        const nextDay = utcToZonedTime(addDays(date, 1), 'Europe/Paris');
        return {
            year: format(date, 'yyyy'),
            previousYear: format(subYears(date, 1), 'yyyy', { locale: localeFR }),
            nextYear: format(addYears(date, 1), 'yyyy', { locale: localeFR }),
            month: format(date, 'MM/yyyy'),
            previousMonth: format(previousMonth, 'MM/yyyy', { locale: localeFR }),
            nextMonth: format(nextMonth, 'MM/yyyy', { locale: localeFR }),
            day: format(date, 'dd/MM/yyyy', { locale: localeFR }),
            previousDay: format(previousDay, 'dd/MM/yyyy', { locale: localeFR }),
            nextDay: format(nextDay, 'dd/MM/yyyy', { locale: localeFR }),
            monthInLetter: format(date, 'MMMM yyyy', { locale: localeFR }),
            previousMonthInLetter: format(previousMonth, 'MMMM yyyy', { locale: localeFR }),
            nextMonthInLetter: format(nextMonth, 'MMMM yyyy', { locale: localeFR }),
            dayInLetter: format(date, 'd MMMM yyyy', { locale: localeFR }),
            previousDayInLetter: format(previousDay, 'd MMMM yyyy', { locale: localeFR }),
            nextDayInLetter: format(nextDay, 'd MMMM yyyy', { locale: localeFR }),
        };
    }
};