import { CwtApiEntity, CwtProp, CwtSchema } from "@cawita/core-front/api";
import { Accounting } from "@server-shared";
import { UploadFile } from "../common";
import { Company } from "../company";
import { CustomFieldValues } from "../custom-field";
import { Customer, CustomerContact } from "../customer";
import { BillingProduct } from "./billing-product.model";
import { CompanyBillingProduct } from "./company-product.model";

export type BillingType = 'quote' | 'invoice';

@CwtSchema()
export class BillingItemProduct extends BillingProduct {
    @CwtProp({ ref: CompanyBillingProduct }) companyProduct: CompanyBillingProduct;
}

export class Billing extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp({ ref: Customer }) customer: Customer;
    @CwtProp({ ref: CustomerContact }) customerContact: CustomerContact;
    @CwtProp() ref: string;
    @CwtProp() refInt: number;
    @CwtProp() defaultVatRate: number;
    @CwtProp() title: string;
    @CwtProp() date: number;

    @CwtProp() isVisibleToCustomer: boolean;
    @CwtProp() amount: number;
    @CwtProp() vatAmount: number;
    @CwtProp() template: any;
    @CwtProp() customFieldValues: CustomFieldValues;

    public get grossAmount() { return Accounting.sumAmounts(this.amount, this.vatAmount); }
}

export class BillingItem extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp() unitPrice: number;
    @CwtProp() quantity: number;
    @CwtProp() vatRate: number;
    @CwtProp() description: string;
    @CwtProp() title: string;
    @CwtProp({ ref: UploadFile }) medias: UploadFile[];
    @CwtProp() displayProductsInPdf: boolean;
    @CwtProp({ ref: BillingItemProduct }) products: BillingItemProduct[];
    @CwtProp() order: number;

    public get amount() { return Accounting.sumAmounts((this.unitPrice ?? 0) * (this.quantity ?? 0)); }
    public get vatAmount() { return Accounting.sumAmounts((this.unitPrice ?? 0) * (this.quantity ?? 0) * (this.vatRate ?? 0)); }
    public get grossAmount() { return Accounting.sumAmounts(this.amount, this.vatAmount); }
}

export class BillingTemplate extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp() name: string;

    @CwtProp({ ref: UploadFile }) logo: UploadFile;
    @CwtProp() footer: string;
    @CwtProp() primaryColor: string;
    @CwtProp() hideWatermark: boolean;

    @CwtProp() isMain: boolean;
}

export function billingSearchMapper(search: string) {
    return {
        or: [
            { ref: { regexp: search } },
            { title: { regexp: search } },
        ]
    };
}

export function billingItemSearchMapper(search: string) {
    return {
        or: [
            { title: { regexp: search } },
            { description: { regexp: search } },
        ]
    };
}