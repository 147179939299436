import { CwtApiSchema, CwtProp, CwtSchema } from "@cawita/core-front/api";

export function BillingProductSearchMapper(search: string) { return { name: { regexp: search } }; }

export enum BillingProductUnit {
    Unit = 'unit',
    Day = 'day',
    Hour = 'hour',
    M2 = 'm2',
    M3 = 'm3',
}

export class BillingProduct extends CwtApiSchema {
    @CwtProp() name: string;
    @CwtProp() unit: BillingProductUnit;
    @CwtProp() unitPrice: number;
    @CwtProp() unitCost: number;
    @CwtProp() vatCost: number;
    @CwtProp() quantity: number;
}