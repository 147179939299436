import { isset, sameId } from "@cawita/core-front";
import { getYear } from "date-fns";
import { CompanyConfig } from "../../config/company.config";
import { Company, CompanyAccess, Customer, BillingReferenceSegment, BillingReferenceSegmentType } from "../../models";

export function getPreferredCompany(companies: Company[], accesses: CompanyAccess[], current?: Company | string): Company {
    if (!companies?.length) return null;
    if (!accesses?.length) return null;
    if (isset(current)) {
        const inCompanies = findCompanyInArray(companies, current);
        if (inCompanies) return inCompanies;
    }

    const ownerAccesses = accesses.filter(access => access.isOwner);
    const ownedCompanies = companies.filter(company => ownerAccesses.some(access => sameId(access.company, company)));
    const nonOwnedCompanies = companies.filter(company => !ownerAccesses.some(access => sameId(access.company, company)));

    if (ownedCompanies.length) return ownedCompanies[0];
    return nonOwnedCompanies[0];
}


export function findCompanyInArray(companies: Company[], company: Company | string) {
    if (!isset(company)) return null;
    return companies?.find(c => sameId(c, company)) ?? null;
}

export function findAccessForCompany(accesses: CompanyAccess[], company: Company | string) {
    if (!isset(company)) return null;
    return accesses?.find(c => sameId(c.company, company)) ?? null;
}

export function findCompanyAndAccessInArrays(companies: Company[], accesses: CompanyAccess[], company: Company | string) {
    const c = findCompanyInArray(companies, company);
    const a = findAccessForCompany(accesses, company);
    if (!isset(c) || !isset(a)) return { company: null, access: null };
    return { company: c, access: a };
}

export function getDefaultReferenceSegments(): BillingReferenceSegment[] {
    return [{
        type: BillingReferenceSegmentType.Increment,
        separator: '-',
    }]
}

export function generateQuoteReference(company: Company, customer?: Customer, year?: number) {
    return _createRef(
        company?.quoteSettings?.reference?.segments,
        company?.increments?.quote,
        company?.quoteSettings?.reference?.companyRef,
        customer?.customerRef,
        year
    );
}

export function generateInvoiceReference(company: Company, customer?: Customer, year?: number) {
    return _createRef(
        company?.invoiceSettings?.reference?.segments,
        company?.increments?.invoice,
        company?.invoiceSettings?.reference?.companyRef,
        customer?.customerRef,
        year
    );
}


function _createRef(segments: BillingReferenceSegment[], increment?: number, companyRef?: string, customerRef?: string, year?: number) {
    if (!segments?.length) return '';

    const placeholders = CompanyConfig.segmentPlaceholders
    const typesValues: Record<BillingReferenceSegmentType, string | null> = {
        companyRef: companyRef,
        customerRef: customerRef,
        increment: increment?.toString(),
        year: year?.toString()
    };

    return segments.reduce((ref, segment, index, arr) => {
        const isLast = index >= arr.length - 1;
        if (isLast) return `${ref}${typesValues[segment.type] || placeholders[segment.type]}`;
        return `${ref}${typesValues[segment.type] || placeholders[segment.type]}${segment.separator}`;
    }, '');
}