import { CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { TemplateSchema } from "../common/template.model";
import { Company } from "./company.model";

export enum CompanyTemplateUseCase {
    SendQuote = 'sendQuote',
    SendQuoteAttachment = 'sendQuoteAttachment',
    SendInvoice = 'sendInvoice',
    SendInvoiceAttachment = 'sendInvoiceAttachment',
    SendCommercialOffer = 'sendCommercialOffer',
    SendCustomerConformityEvent = 'sendCustomerConformityEvent'
}


@CwtModel({ name: 'company-template' })
@CwtCrudContract({ path: 'company/:companyId/template' })
export class CompanyTemplate extends TemplateSchema {
    @CwtProp({ ref: Company }) company?: Company;
}