import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp, CwtSubCrudContract } from "@cawita/core-front/api";
import { Contract } from "./contract.model";
import { FundType } from "./fund-type.model";
import { Company } from "./company";
import { Customer } from "./customer";

@CwtModel({ name: 'fund' })
@CwtCrudContract({ path: '/fund' })
export class Fund extends CwtApiEntity {
    @CwtProp() name: string;
    @CwtProp() isin: string;
    @CwtProp() productId: number;
    @CwtProp({ ref: FundType }) fundType: FundType;
}

export type Repartitions = {
    fund: Fund,
    amount: number;
}

@CwtModel({ name: 'contract-money-repartition' })
@CwtSubCrudContract({ parent: Contract, parentPath: '/company/:companyId/contract', subPath: '/contract-money-repartition' })
@CwtSubCrudContract({ parent: Customer, parentPath: '/company/:companyId/customer', subPath: '/contract-money-repartition' })
export class ContractMoneyRepartition extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp({ ref: Customer }) customer: Customer;
    @CwtProp({ ref: Contract }) contract: Contract;
    @CwtProp() date: number;
    @CwtProp() comment: string;
    @CwtProp() repartitions: Repartitions[];
}