const { asArray, isset, isEmptyOrNotSet } = require('@hints/utils/data');
const { getInt8AsDate } = require('@hints/utils/date');
const { isDay, isArray } = require('@hints/utils/types');
const { compareAsc, startOfDay } = require('date-fns');
const { isGenerationDateBetween } = require('./utils');

function validateRecurringDates(recurringObj) {
    if (isEmptyOrNotSet(recurringObj.startDate)) return false;
    if (!isset(recurringObj.dates)) return false; // allow empty arrays there
    if (!isArray(recurringObj.dates)) return false;
    return recurringObj.dates.every(d => isDay(d));
}

function sanitizeRecurringForDates(recurringObj) {
    delete recurringObj.frequencies;
    delete recurringObj.rules;

    const dates = recurringObj.dates || [];
    const sorted = dates.sort();
    recurringObj.dates = sorted;
    recurringObj.startDate = sorted[0];
    recurringObj.endDate = sorted[sorted.length - 1];
}

function getDatesNextRunDatesBetween(dates, datesToGenerate = 1, startDate, endDate, minDate, maxDate) {    
    const sorted = asArray(dates || []).map(getInt8AsDate).sort(compareAsc);
    const validDates = sorted.filter(date => isGenerationDateBetween(date, startDate, endDate, minDate, maxDate));
    return validDates.slice(0, datesToGenerate);
}

module.exports = {
    validateRecurringDates,
    sanitizeRecurringForDates,
    getDatesNextRunDatesBetween
};