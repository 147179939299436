import { CwtApiEntity, CwtApiSchema, CwtCrudContract, CwtModel, CwtProp, CwtSchema } from "@cawita/core-front/api";
import { Address, LegalForm, UploadFile, User } from "../common";
import { Company } from "../company";
import { CustomFieldValues } from "../custom-field";

export enum CustomerStatus {
    Prospect = 'prospect',
    Client = 'client'
}

export enum FamilySituations {
    Single = 'single',
    Married = 'married',
    Pacs = 'pacs',
    Cohabitation = 'cohabitation',
    Divorced = 'divorced',
    Widow = 'widow'
}

export enum WeddingContracts {
    FurnitureAndAcquisite = 'furnitureAndAcquisite',
    Community = 'community',
    LegalCommunity = 'legalCommunity',
    ParticipationAcquisite = 'participationAcquisite',
    Separation = 'separation'
}

export enum MonthlyIncomes {
    LessThan1500 = '<1500',
    LessThan2500 = '<2500',
    LessThan5000 = '<5000',
    LessThan10000 = '<10000',
    LessThan20000 = '<20000',
    LessThan40000 = '<40000',
    MoreThan40000 = '>40000',
}

export enum FinancialAssets {
    LessThan100000 = '<100000',
    LessThan250000 = '<250000',
    LessThan500000 = '<500000',
    LessThan1000000 = '<1000000',
    LessThan1500000 = '<1500000',
    LessThan2500000 = '<2500000',
    MoreThan2500000 = '>2500000'
}

export enum PaymentTermValue {
    Debit = 'debit',
    BankTransger = 'bankTransfer',
}

export function CustomerSearchMapper(search: string) {
    return ({
        or: [
            { customerRef: { regexp: search } },
            { name: { regexp: search } },
            { siren: { regexp: search } }
        ]
    })
}

@CwtSchema()
export class ForcedStatus extends CwtApiSchema {
    @CwtProp() isActive: boolean;
    @CwtProp({ ref: Date }) date: Date;
    @CwtProp({ ref: User }) user: User;
}

@CwtModel({ name: 'customer' })
@CwtCrudContract({ path: '/company/:companyId/customer', searchMapper: CustomerSearchMapper })
export class Customer extends CwtApiEntity {
    @CwtProp() name: string;
    @CwtProp() siren: string;
    @CwtProp() status: CustomerStatus;
    @CwtProp({ ref: ForcedStatus }) forcedStatus: ForcedStatus;
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp() customerRef: string;
    @CwtProp({ ref: UploadFile }) logo: UploadFile;
    @CwtProp({ ref: Address }) address: Address;
    @CwtProp() legalForm: LegalForm;
    @CwtProp() vatNumber: string;
    @CwtProp() observation: string;
    @CwtProp() customFieldValues: CustomFieldValues;
    @CwtProp() paymentTermValue: PaymentTermValue;
    @CwtProp() familySituation: FamilySituations;
    @CwtProp() weddingContract: WeddingContracts;
    @CwtProp() acquetCompany: boolean;
    @CwtProp() situationDate: Date;
    @CwtProp() hasTestament: boolean;
    @CwtProp() hasDonnationToLastLiving: boolean;
    @CwtProp() monthlyIncome: MonthlyIncomes;
    @CwtProp() realEstateAssets: FinancialAssets;
    @CwtProp() financialAssets: FinancialAssets;
}