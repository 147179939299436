import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { Company } from "./company";
import { Customer } from "./customer";
import { UploadFile } from "./common";

export enum PeriodType {
    Month = 'month',
    Year = 'year'
}

export enum EventTypeConformity {
    Plan = 'plan',
    LastSigned = 'lastSigned',
    History = 'history'
}

export enum SignatureType {
    Elect = 'elec',
    Paper = 'paper'
}

export type Frequency = {
    step: number;
    periodType: PeriodType;
}

export enum ConformityColors{
    inTime = '#f8ffed',
    late = '#fae9e6',
    oneMonth = '#fcf3e8'
}

export enum CustomerConformityStatus {
    Signed = 'signed',
    Pending = 'pending',
}

@CwtModel({ name: 'conformity' })
@CwtCrudContract({ path: 'company/:companyId/conformity' })
export class Conformity extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp() name: string;
    @CwtProp() isDefault: boolean;
    @CwtProp() frequency: Frequency;
}

@CwtModel({ name: 'customer-conformity-requirement' })
@CwtCrudContract({ path: 'company/:companyId/customer-conformity-requirement' })
export class CustomerConformityRequirement extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp({ ref: Customer }) customer: Customer;
    @CwtProp({ ref: Conformity }) conformity: Conformity;
}

@CwtModel({ name: 'conformity-template' })
@CwtCrudContract({ path: 'company/:companyId/conformity-template' })
export class ConformityTemplate extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp({ ref: Conformity }) conformity: Conformity;
    @CwtProp({ ref: UploadFile }) document: UploadFile;
    @CwtProp() name: string;
}

@CwtModel({ name: 'customer-conformity-event' })
@CwtCrudContract({ path: 'company/:companyId/customer-conformity-event' })
@CwtCrudContract({ path: 'for-customer/:customerToken/customer-conformity-event', identifier: 'for-customer', searchMapper: conformitySearchMapper })
export class CustomerConformityEvent extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp({ ref: Conformity }) conformity: Conformity;
    @CwtProp({ ref: Customer }) customer: Customer;
    @CwtProp({ ref: ConformityTemplate }) conformityTemplate: ConformityTemplate;
    @CwtProp() requestedDate: number
    @CwtProp() signedDate: Date;
    @CwtProp() emailDate: Date;
    @CwtProp() showInCustomerSpace: boolean;
    @CwtProp({ ref: UploadFile }) signedDocument: UploadFile;
    @CwtProp({ ref: CustomerConformityEvent }) previous: CustomerConformityEvent;
    @CwtProp({ ref: CustomerConformityEvent }) next: CustomerConformityEvent;
    @CwtProp() eventType: EventTypeConformity;
    @CwtProp() signatureType: SignatureType;
}

export function conformitySearchMapper(search: string) {
    return {
        or: [
            {'conformity.name': { regexp: search }},
        ]
    };
}
