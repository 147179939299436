import { CwtApiEntity, CwtModel, CwtProp, CwtSubCrudContract } from "@cawita/core-front/api";
import { Company } from "./company";
import { Customer, CustomerContact } from "./customer";
import { Contract } from "./contract.model";

export type Repartition = {
    contact: CustomerContact;
    contactName: string;
    percentage: number;
}

@CwtModel({ name: 'contract-beneficiary' })
@CwtSubCrudContract({ parent: Contract, parentPath: '/company/:companyId/contract', subPath: '/contract-beneficiary' })
export class ContractBeneficiary extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp({ ref: Customer }) customer: Customer;
    @CwtProp({ ref: Contract }) contract: Contract;
    @CwtProp() date: number;
    @CwtProp() detail: string;
    @CwtProp() repartitions: Repartition[];
}