import { CwtModel, CwtProp, CwtSubCrudContract } from "@cawita/core-front/api";
import { Customer } from "../customer";
import { BillingProduct, BillingProductSearchMapper } from "./billing-product.model";
import { CustomerNegoPrice } from "./customer-nego-price.model";

@CwtModel({ name: 'customer-billing-product' })
@CwtSubCrudContract({ parent: Customer, parentPath: 'company/:companyId/customer', subPath: 'company-product', searchMapper: BillingProductSearchMapper })
export class CustomerBillingProduct extends BillingProduct {
    @CwtProp() isActive: boolean;
    @CwtProp({ ref: CustomerNegoPrice }) negoPrice?: CustomerNegoPrice;

    public get effectiveUnitPrice(): number {
        console.log(this);
        return this.negoPrice?.unitPrice ?? this.unitPrice ?? 0;
    }
}