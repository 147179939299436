import { Inject, Injectable } from '@angular/core';
import { CwtI18nService, asArray, getId } from '@cawita/core-front';
import { CwtApiPageResponse, CwtApiService, CwtCrudContract, CwtCrudService, CwtRequestConfig, CwtSubCrudContract, CwtSubCrudService, isPaginated } from '@cawita/core-front/api';
import { createDictionary } from '@hints/utils/data';
import { Observable, map, switchMap } from 'rxjs';
import { Customer, CustomerAccessToken, CustomerContact } from '../../models';

@Injectable({ providedIn: 'root' })
export class CustomerApi {
    constructor(
        private i18n: CwtI18nService,
        private api: CwtApiService,
        @Inject(CwtSubCrudContract.get(CustomerAccessToken, Customer)) private accessTokenCrud: CwtSubCrudService<CustomerAccessToken>,
        @Inject(CwtCrudContract.get(CustomerContact)) private customerContactCrud: CwtCrudService<CustomerContact>,
    ) { }

    public searchCustomers(search: string, filters?: any, config?: CwtRequestConfig): Observable<CwtApiPageResponse<Customer> | Customer[]> {
        return this.api.get<CwtApiPageResponse<Customer> | Customer[]>(`company/:companyId/customer/search`, filters, {
            ...(config ?? {}),
            options: {
                ...(config?.options) ?? {},
                search: search
            }
        }).pipe(
            map(res => {
                if (isPaginated(res)) return { ...res, data: res.data.map(c => new Customer(c)) }
                return res.map(c => new Customer(c));
            })
        );
    }

    public getContactsForCustomer(customers: Customer[]): Observable<Record<string, CustomerContact[]>> {
        return this.customerContactCrud.getArray({ customer: { in: asArray(customers).map(getId) } }).pipe(
            map(contacts => createDictionary(
                contacts,
                contact => getId(contact.customer),
                (contacts, contact) => contacts.concat(contact),
                () => []
            ))
        );
    }

    public getCustomerSpace(customer: Customer | string): Observable<CustomerAccessToken> {
        return this.accessTokenCrud.getOne(getId(customer), {
            isRevoked: false
        });
    }

    public openDefaultCustomerSpace(customer: Customer | string): Observable<CustomerAccessToken> {
        return this.i18n.get('defaults.customer-space-label').pipe(
            switchMap(label => this.accessTokenCrud.create(getId(customer), {
                label: label
            }))
        );
    }

    public setResourcesVisibleToCustomer(customer: Customer | string, isVisibleToCustomer = false): Observable<void> {
        return this.api.patch(`company/:companyId/customer/${getId(customer)}/resource/is-visible-to-customer`, {
            isVisibleToCustomer
        });
    }

    public merge(payload: any, config?: CwtRequestConfig) {
        return this.api.post(`company/:companyId/customer/merge`, payload, config).pipe(
            map(customer => new Customer(customer))
        );
    }

    public updateForcedStatus(customer: Customer, payload?: any, config?: CwtRequestConfig) {
        return this.api.patch(`company/:companyId/customer/${getId(customer)}/status`, payload, config);
    }

    public deleteForcedStatus(customer: Customer, config?: CwtRequestConfig) {
        return this.api.delete(`company/:companyId/customer/${getId(customer)}/status`, {}, config);
    }
}