import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { hasRequiredPermission } from '@cawita/core-front/auth';
import { CwtDirectiveView } from '@cawita/core-front/common';
import { Subscription } from 'rxjs';
import { CompanyStore } from '../company.store';

export type HasAnyCompanyPermissionContext = {
    $implicit: boolean;
}

/**
 * Exposes a boolean value
 * Input format: 'permission' or ['perm1', 'perm2']
 */
@Directive({ selector: '[cwtHasAnyCompanyPermission]' })
export class HasAnyCompanyPermissionDirective implements OnDestroy, OnInit {
    private _sub: Subscription;
    private _ctx: HasAnyCompanyPermissionContext = { $implicit: false };
    private _view = new CwtDirectiveView(this.vcRef, this.tRef, this._ctx);

    @Input('cwtHasAnyCompanyPermission') set permissions(permissions: string | string[]) {
        if (this._sub) this._unsubscribe();
        this._sub = this.companyStore.stableState$.subscribe((state) => {
            const hasPermission = state.accesses?.length && state.accesses.some(a => a.isOwner === true || hasRequiredPermission(a.permissions, permissions));
            this._ctx.$implicit = hasPermission;
        });
    }

    constructor(
        private tRef: TemplateRef<any>,
        private vcRef: ViewContainerRef,
        private companyStore: CompanyStore
    ) {
    }

    ngOnInit(): void {
        this._view.ensureState(true);
    }

    ngOnDestroy(): void {
        this._unsubscribe();
    }

    private _unsubscribe() {
        this._sub?.unsubscribe();
        this._sub = null;
    }
}
