import { getYear } from "date-fns";
import { BillingReferenceSegmentType } from "../models";

export type ICompanyConfig = {
    defaultVatRates: number[];
    permissionsPerCategory: { category: string, permissions: string[] }[];
    segmentColors: Record<BillingReferenceSegmentType, { foreground: string, background: string }>;
    segmentPlaceholders: Record<BillingReferenceSegmentType, string>;
}

export const CompanyConfig: ICompanyConfig = {
    defaultVatRates: [0.2, 0.1, 0.055, 0.021],
    permissionsPerCategory: [
        {
            category: 'settings',
            permissions: ['manageCompanyAccess', 'manageCompanySettings', 'manageConformities']
        },
        {
            category: 'billing',
            permissions: [
                'seeCustomers', 'manageCustomers',
                'seeQuotes', 'manageQuotes',
                'seeInvoices', 'manageInvoices',
                'billingExport'
            ]
        },
        {
            category: 'payments',
            permissions: ['managePayments', 'seePayments']
        },
        {
            category: 'global',
            permissions: ['manageMetadata']
        }
    ],
    segmentColors: {
        [BillingReferenceSegmentType.CompanyRef]: {
            foreground: '#c41d7f',
            background: '#fff0f6'
        },
        [BillingReferenceSegmentType.CustomerRef]: {
            foreground: '#d48806',
            background: '#fffbe6'
        },
        [BillingReferenceSegmentType.Increment]: {
            foreground: '#389e0d',
            background: '#f6ffed'
        },
        [BillingReferenceSegmentType.Year]: {
            foreground: '#1d39c4',
            background: '#f0f5ff'
        },
    },
    segmentPlaceholders: {
        [BillingReferenceSegmentType.CompanyRef]: 'XXX',
        [BillingReferenceSegmentType.CustomerRef]: 'ZZZ',
        [BillingReferenceSegmentType.Increment]: '1',
        [BillingReferenceSegmentType.Year]: (getYear(new Date())).toString(),
    }
}