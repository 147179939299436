import { Directive, Input, TemplateRef } from '@angular/core';
import { NzTabComponent, NzTabSetComponent } from 'ng-zorro-antd/tabs';
import { TabsetDirective } from './tabset.directive';

@Directive({
  selector: 'nz-tab[cwtTab]'
})
export class TabDirective {

  @Input('cwtTab') public tabId: string;

  constructor(
    private tabSet: NzTabSetComponent,
    private tab: NzTabComponent
  ) { }

  public getTabIndex(index: number): number { return this.tabSet.getTabIndex(this.tab, index); }

}
