import { CwtApiSchema, CwtCrudContract, CwtModel, CwtProp, CwtSchema } from "@cawita/core-front/api";
import { BillingProduct } from "./billing-product.model";
import { InvoiceStatus } from "./invoice-status.model";
import { QuoteStatus } from "./quote-status.model";

export function CompanyProductSearchMapper(search: string) { return { name: { regexp: search } }; }

export class _CompanyProductStatProxy {
    constructor(values: any) {
        return Object.keys(values).reduce((map, status) => {
            map[status] = new CompanyProductStatEntry(values[status]);
            return map;
        }, {});
    }
}

@CwtSchema()
export class CompanyProductStatEntry extends CwtApiSchema {
    nb: number;
    totalPrice: number;
    totalCost: number;
}

export type CompanyProductStats<T extends string | number> = { [key in T]: CompanyProductStatEntry; }

@CwtModel({ name: 'company-billing-product' })
@CwtCrudContract({ path: 'company/:companyId/company-product', searchMapper: CompanyProductSearchMapper })
export class CompanyBillingProduct extends BillingProduct {
    @CwtProp() isActive: boolean;
    @CwtProp({ ref: _CompanyProductStatProxy }) quoteStat: CompanyProductStats<QuoteStatus>;
    @CwtProp({ ref: _CompanyProductStatProxy }) invoiceStat: CompanyProductStats<InvoiceStatus>;
}