// @index(['./!(*.spec).ts', './*/index.ts'], f => `export * from '${f.path.replace(/\/index$/, '')}';`)
export * from './analytics';
export * from './banking';
export * from './billing';
export * from './bookmark';
export * from './common';
export * from './company';
export * from './conformity.model';
export * from './contract-beneficiary.model';
export * from './contract-money-repartition.model';
export * from './contract-type.model';
export * from './contract.model';
export * from './custom-field';
export * from './customer';
export * from './fund-type.model';
export * from './management-act.model';
export * from './money-holder.model';
export * from './money-move.model';
export * from './money-origin.model';
// @endindex