import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp, CwtSubCrudContract } from "@cawita/core-front/api";
import { Company } from "../company";
import { Customer } from "./customer.model";

export enum Gender {
    Mr = 'mr',
    Ms = 'ms'
}

export enum ContactType {
    Secondary = 'secondary',
    Children = 'children',
    Primary = 'primary',
}

@CwtModel({ name: 'customer-contact' })
@CwtSubCrudContract({ parent: Customer, parentPath: '/company/:companyId/customer', subPath: '/customer-contact', searchMapper: (search) => ({ firstName: { regexp: search } }) })
@CwtCrudContract({ path: '/company/:companyId/customer-contact' })
export class CustomerContact extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp({ ref: Customer }) customer: Customer;
    @CwtProp() firstName: string;
    @CwtProp() lastName: string;
    @CwtProp() gender: Gender;
    @CwtProp() birthName: string;
    @CwtProp() email: string;
    @CwtProp() phone: string;
    @CwtProp() mobile: string;
    @CwtProp() birthDate: number;
    @CwtProp() birthPlace: string;
    @CwtProp() position: string;
    @CwtProp() taxResidence: string;
    @CwtProp() type: ContactType;
    @CwtProp() observation: string;
}