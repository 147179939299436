const rules = require('./rules');
const frequencies = require('./frequencies');
const dates = require('./dates');
const { isEmptyOrNotSet, isset } = require('@hints/utils/data');
const { getRecurringMinDate, getRecurringMaxDate, getRecurringStartDate, getRecurringEndDate } = require('./utils');
const { startOfDay } = require('date-fns');
const { isNull } = require('@hints/utils/types');

/**
 * @typedef {Object} ComputeNextRunDatesOptions
 * @property {Date} minDate
 * @property {Date} maxDate
 */

function validateRecurringFields(recurringObj = {}) {
    if (!isset(recurringObj)) return false;
    if (isEmptyOrNotSet(recurringObj.type)) return false;
    switch (recurringObj.type) {
        case 'frequencies': return frequencies.validateRecurringFrequencies(recurringObj);
        case 'dates': return dates.validateRecurringDates(recurringObj);
        case 'rules': return rules.validateRecurringRules(recurringObj);
        default: return false;
    }
}

function clearRecurringFields(recurringObj) {
    if (!isset(recurringObj)) return;
    delete recurringObj.type;
    delete recurringObj.frequencies;
    delete recurringObj.dates;
    delete recurringObj.rules;
    delete recurringObj.startDate;
    delete recurringObj.endDate;
}

function _applyPreviousValueField(field, recurringObj, previousValue) {
    recurringObj[field] = previousValue[field];
}

function _applyPreviousValueFieldIfNoChange(field, recurringObj, previousValue) {
    if (!isEmptyOrNotSet(recurringObj[field])) return; // the field will change value
    if (isNull(recurringObj[field])) return; // the field will be unset
    if (!isset(previousValue[field])) return; // the previous value does not have this field
    _applyPreviousValueField(field, recurringObj, previousValue);
}

function sanitizeRecurring(recurringObj, previousValue) {
    if (!isset(recurringObj)) return recurringObj;

    if (isset(previousValue)) {
        _applyPreviousValueFieldIfNoChange('startDate', recurringObj, previousValue);
        _applyPreviousValueFieldIfNoChange('endDate', recurringObj, previousValue);
        _applyPreviousValueFieldIfNoChange('isActive', recurringObj, previousValue);
        _applyPreviousValueFieldIfNoChange('type', recurringObj, previousValue);
        _applyPreviousValueFieldIfNoChange('frequencies', recurringObj, previousValue);
        _applyPreviousValueFieldIfNoChange('dates', recurringObj, previousValue);
        _applyPreviousValueFieldIfNoChange('rules', recurringObj, previousValue);
        _applyPreviousValueField('lastRanDate', recurringObj, previousValue);
        // If previous value as ran, we need to force some fields
        if (isset(previousValue.lastRanDate)) {
            _applyPreviousValueField('startDate', recurringObj, previousValue);
        }
    }
    
    switch (recurringObj.type) {
        case 'frequencies': frequencies.sanitizeRecurringForFrequencies(recurringObj); break;
        case 'dates': dates.sanitizeRecurringForDates(recurringObj); break;
        case 'rules': rules.sanitizeRecurringForRules(recurringObj); break;
        default: break;
    }

    const nextRunDates = _computeNextRunDates(recurringObj, 1);
    recurringObj.nextRunDate = nextRunDates[0] || null;
    return recurringObj;
}


/**
 * @internal
 */
function _computeNextRunDates(recurringObj, datesToGenerate = 1, options = {}) {
    if (!isset(recurringObj)) return [];
    const minDate = getRecurringMinDate(recurringObj, options ? options.minDate : null);
    const maxDate = getRecurringMaxDate(recurringObj, options ? options.maxDate : null);
    const startDate = getRecurringStartDate(recurringObj);
    const endDate = getRecurringEndDate(recurringObj);

    switch (recurringObj.type) {
        case 'frequencies': return frequencies.getFrequenciesNextRunDatesBetween(recurringObj.frequencies, datesToGenerate, startDate, endDate, minDate, maxDate);
        case 'dates': return dates.getDatesNextRunDatesBetween(recurringObj.dates, datesToGenerate, startDate, endDate, minDate, maxDate);
        case 'rules': return rules.getRuleNextRunDatesBetween(recurringObj.rules, datesToGenerate, startDate, endDate, minDate, maxDate);
        default: return [];
    }
}

/**
 * 
 * @param {any} recurringObj 
 * @param {number} datesToGenerate 
 * @param {ComputeNextRunDatesOptions} options 
 * @returns {Date[]}
 */
function computeNextRunDates(recurringObj, datesToGenerate = 1, options = {}) {
    const nextRunDates = _computeNextRunDates(recurringObj, datesToGenerate, options);
    return nextRunDates.map(startOfDay);
}

module.exports = {
    validateRecurringFields,
    clearRecurringFields,
    sanitizeRecurring,
    computeNextRunDates
};