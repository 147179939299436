import { Pipe, PipeTransform } from '@angular/core';
import { Company, Customer } from '../../models';
import { generateInvoiceReference, generateQuoteReference } from './company.utils';

@Pipe({
  name: 'companyBillingRef'
})
export class CompanyBillingRefPipe implements PipeTransform {

  transform(company: Company, type: 'quote' | 'invoice' = 'quote', customer?: Customer): string {
    switch (type) {
      case 'quote': return generateQuoteReference(company, customer);
      case 'invoice': return generateInvoiceReference(company, customer);
      default: return '';
    }
  }

}
