import { CwtCrudContract, CwtModel, CwtProp, CwtSubCrudContract } from "@cawita/core-front/api";
import { StatusDates, StatusDatesProxy } from "../common";
import { Billing, BillingItem, billingItemSearchMapper, billingSearchMapper } from "./billing.model";
import { InvoiceTemplate } from "./invoice-template.model";
import type { Quote } from "./quote.model";
import { Accounting } from "@server-shared";
import { InvoiceStatus } from "./invoice-status.model";

export enum InvoiceType {
    Invoice = 'invoice',
    Credit = 'credit'
}

@CwtModel({ name: 'invoice' })
@CwtCrudContract({ path: '/company/:companyId/invoice', searchMapper: billingSearchMapper })
@CwtCrudContract({ path: 'for-customer/:customerToken/invoice', identifier: 'for-customer', searchMapper: billingSearchMapper })
export class Invoice extends Billing {
    @CwtProp({ ref: 'invoice-template' }) template: InvoiceTemplate;
    @CwtProp() totalPaid: number;
    @CwtProp() totalCredited: number;
    @CwtProp() totalVatCredited: number;
    @CwtProp({ ref: 'invoice' }) invoice: Invoice;
    @CwtProp({ ref: 'quote' }) quote: Quote;
    @CwtProp() type: InvoiceType;
    @CwtProp() date: number;
    @CwtProp() paymentTermValue: number;
    @CwtProp() status: InvoiceStatus;
    @CwtProp({ ref: StatusDatesProxy }) statusDates: StatusDates<InvoiceStatus>;

    public get grossCredited() { return Accounting.sumAmounts(this.totalCredited, this.totalVatCredited); }
    public get totalDue() { return Accounting.sumAmounts(this.grossAmount, -this.grossCredited); }
    public get remainingDue() { return Accounting.sumAmounts(this.totalDue, -this.totalPaid); }
}

@CwtModel({ name: 'invoice-item' })
@CwtCrudContract({ path: 'company/:companyId/invoice/item' })
@CwtSubCrudContract({ parent: Invoice, parentPath: 'company/:companyId/invoice', subPath: 'invoice-item', searchMapper: billingItemSearchMapper })
@CwtSubCrudContract({ parent: Invoice, parentPath: 'for-customer/:customerToken/invoice', identifier: 'for-customer', subPath: 'invoice-item', searchMapper: billingItemSearchMapper })
export class InvoiceItem extends BillingItem {
    @CwtProp({ ref: 'type' }) invoice: Invoice;
}

export type QuoteInvoicingDTO = {
    type: InvoiceType;
    date?: number;
    title: string;
    quote: string;
    defaultVatRate?: number;
    items: {
        title: string;
        quantity: number;
        description: string;
        unitPrice: number;
        vatRate: number;
    }[];
}

export type InvoiceCreditDTO = {
    date?: number;
    title: string;
    invoice: string;
    defaultVatRate?: number;
    items: {
        title: string;
        quantity: number;
        description: string;
        unitPrice: number;
        vatRate: number;
    }[];
}