import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp, CwtSubCrudContract } from "@cawita/core-front/api";
import { Company } from "./company";
import { Contract } from "./contract.model";
import { Customer } from "./customer";

export enum ManagementActType {
    Subscription = 'subscription',
    Rebuy = 'rebuy',
    Payment = 'payment',
    Arbitrage = 'arbitrage',
    Other = 'other'
}

export enum ManagementActStatus {
    Todo = 'todo',
    Done = 'done',
    Followup = 'followup',
    ToFollow = 'toFollow'
}

@CwtModel({ name: 'change-type' })
@CwtCrudContract({ path: '/change-type' })
export class ChangeType extends CwtApiEntity {
    @CwtProp() name: string;
}

@CwtModel({ name: 'management-act' })
@CwtCrudContract({ path: 'company/:companyId/management-act'})
@CwtSubCrudContract({ parent: Customer, parentPath: '/company/:companyId/customer', subPath: '/management-act' })
export class ManagementAct extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp({ ref: Customer }) customer: Customer;
    @CwtProp({ ref: Contract }) contract: Contract;
    @CwtProp() sentDate: number;
    @CwtProp() followupDate: number;
    @CwtProp() type: ManagementActType;
    @CwtProp({ ref: ChangeType }) changeType: ChangeType;
    @CwtProp() amount: number;
    @CwtProp() status: ManagementActStatus;
}
