import { BooleanInput } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Directive, ElementRef, HostListener, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isset } from '@hints/utils/data';
import { Formatting } from '@server-shared';

@Directive({
  selector: 'input[cwt-percent-input]',
  standalone: true,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: PercentInputComponent, multi: true },
  ],
})
export class PercentInputComponent implements ControlValueAccessor {

  public value: number;
  public displayValue: number;
  public formattedValue: string;
  @Input() public disabled: BooleanInput;
  public inFocus = false;

  private _onChange = (value: number) => { };
  private _onTouched = () => { };

  constructor(
    private elem: ElementRef,
    private cdRef: ChangeDetectorRef,
  ) { }

  writeValue(obj: number): void {
    if (isset(obj)) {
      this._updateViewValueProperties(obj);
    } else {
      this._updateViewValueProperties(null);
    }
    this._updateViewValue();
    this.cdRef.markForCheck();
  }

  registerOnChange(fn: any): void { this._onChange = fn; }

  registerOnTouched(fn: any): void { this._onTouched = fn; }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  setValue(value: string) {
    if (isset(value) && value.length) {
      const newValue = Formatting.percent.parse(value);
      this._updateViewValueProperties(newValue);
      this._onChange(newValue);
    } else {
      this._updateViewValueProperties(null);
      this._onChange(null);
    }
    this._onTouched();
    this._updateViewValue();
    this.cdRef.markForCheck();
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: any) {
    this.setValue(value);
  }

  @HostListener('focus')
  onFocus() {
    this.inFocus = true;
    this._updateViewValue();
    this.cdRef.markForCheck();
  }

  @HostListener('blur')
  onBlur() {
    this.inFocus = false;
    this._onTouched();
    this._updateViewValue();
    this.cdRef.markForCheck();
  }

  private _updateViewValueProperties(value: number) {
    this.value = isset(value) ? Formatting.percent.round(value) : null;
    this.displayValue = isset(value) ? Formatting.percent.round_non_normalized(value * 100) : null;
    this.formattedValue = isset(value) ? Formatting.percent.format(value) : null;
  }

  private _updateViewValue() {
    if (this.inFocus) {
      this.elem.nativeElement.value = this.displayValue;
    } else {
      this.elem.nativeElement.value = this.formattedValue;
    }
  }
}
