import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp, CwtSubCrudContract } from "@cawita/core-front/api";
import { Company } from "./company";
import { Customer } from "./customer";
import { MoneyOrigin } from "./money-origin.model";
import { ContractType } from "./contract-type.model";
import { MoneyHolder } from "./money-holder.model";

export enum Subscriber {
    Mr = 'Mr',
    Mme = 'Mme',
    MrAndMme = 'Mr&Mme'
}

const ContractSearchMapper = (s) => ({
    or: [
        {'moneyHolder.name': { regexp: s }},
        {'contractType.name': { regexp: s }},
        { contractNumber: { regexp: s } },
    ]
})

@CwtModel({ name: 'contract' })
@CwtCrudContract({ path: 'company/:companyId/contract', searchMapper: ContractSearchMapper })
@CwtSubCrudContract({ parent: Customer, parentPath: '/company/:companyId/customer', subPath: '/contract', searchMapper: ContractSearchMapper })
export class Contract extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp({ ref: Customer }) customer: Customer;
    @CwtProp({ ref: ContractType }) contractType: ContractType;
    @CwtProp() subscriber: Subscriber;
    @CwtProp({ ref: MoneyOrigin }) moneyOrigin: MoneyOrigin;
    @CwtProp({ ref: MoneyHolder }) moneyHolder: MoneyHolder;
    @CwtProp() contractNumber: string;
    @CwtProp() firstDeath: boolean;
    @CwtProp() secondDeath: boolean;
    @CwtProp() totalAmount: number;
}