const { isset } = require('@hints/utils/data');
const { getInt8AsDate } = require('@hints/utils/date');
const { isAfter, isBefore, isSameDay } = require('date-fns');

function _actualRecurringMinDate(recurringObj) {
    if (isset(recurringObj.lastRanDate)) return new Date(recurringObj.lastRanDate);
    if (isset(recurringObj.startDate)) return getInt8AsDate(recurringObj.startDate);
    return new Date;
}

function _actualRecurringMaxDate(recurringObj) {
    if (isset(recurringObj.endDate)) return getInt8AsDate(recurringObj.endDate);
    return null;
}


function getRecurringStartDate(recurringObj) {
    if (isset(recurringObj.startDate)) return getInt8AsDate(recurringObj.startDate);
    return new Date();

}
function getRecurringEndDate(recurringObj) {
    if (isset(recurringObj.endDate)) return getInt8AsDate(recurringObj.endDate);
    return null;
}

function getRecurringMinDate(recurringObj, limit = null) {
    const min = _actualRecurringMinDate(recurringObj);
    if (isset(limit) && (!isset(min) || isBefore(min, limit))) return limit;
    return min || new Date();
}

function getRecurringMaxDate(recurringObj, limit = null) {
    const max = _actualRecurringMaxDate(recurringObj);
    if (isset(limit) && (!isset(max) || isAfter(max, limit))) return limit;
    return max;
}

function isGenerationDateBetween(date, startDate, endDate, minDate, maxDate) {
    return isGenerationDateAfterStart(date, startDate, minDate) && isGenerationDateBeforeEnd(date, endDate, maxDate);
}

function isGenerationDateAfterStart(date, startDate, minDate) {
    if (!isset(date)) return false;
    if (isset(startDate) && !isSameDay(date, startDate) && !isAfter(date, startDate)) return false;
    if (isset(minDate) && !isSameDay(date, minDate) && !isAfter(date, minDate)) return false;
    return true;
}

function isGenerationDateBeforeEnd(date, endDate, maxDate) {
    if (!isset(date)) return false;
    if (isset(endDate) && !isSameDay(date, endDate) && !isBefore(date, endDate)) return false;
    if (isset(maxDate) && !isSameDay(date, maxDate) && !isBefore(date, maxDate)) return false;
    return true;
}

module.exports = {
    getRecurringMinDate,
    getRecurringMaxDate,
    getRecurringStartDate,
    getRecurringEndDate,
    isGenerationDateBetween,
    isGenerationDateAfterStart,
    isGenerationDateBeforeEnd,
};