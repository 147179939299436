import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp, CwtSubCrudContract } from "@cawita/core-front/api";
import { Company } from "./company";
import { Contract } from "./contract.model";
import { Customer } from "./customer";


@CwtModel({ name: 'move-type' })
@CwtCrudContract({ path: '/move-type' })
export class MoveType extends CwtApiEntity {
    @CwtProp() name: string;
}

@CwtModel({ name: 'taxation' })
@CwtCrudContract({ path: '/taxation' })
export class Taxation extends CwtApiEntity {
    @CwtProp() name: string;
}

@CwtModel({ name: 'money-move' })
@CwtSubCrudContract({ parent: Contract, parentPath: '/company/:companyId/contract', subPath: '/money-move' })
export class MoneyMove extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp({ ref: Customer }) customer: Customer;
    @CwtProp({ ref: Contract }) contract: Contract;
    @CwtProp({ ref: MoveType }) moveType: MoveType;
    @CwtProp() date: Date;
    @CwtProp() amount: number;
    @CwtProp() isBeforeLaw2017: boolean;
    @CwtProp({ ref: Taxation }) taxation: Taxation;
}
