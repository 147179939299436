const { isNumber } = require('@hints/utils/types');
const { TemplateConfiguration } = require('../template-utils');
const formatting = require('../formatting');

module.exports = new TemplateConfiguration({
    observations: {
        variables: {
            informationBlock: { type: 'string', label: 'template-variables.quote-information-block' },
            itemListBlock: { type: 'string', label: 'template-variables.quote-item-list-block' },
            signatureBlock: { type: 'string', label: 'template-variables.quote-signature-block' },
            grossAmount: { type: 'currency', label: 'template-variables.quote-gross-amount' },
            netAmount: { type: 'currency', label: 'template-variables.quote-net-amount' },
        },
    }
}, {
    currency: { validate: isNumber, format: v => formatting.currency.format(v) },
});