// address: submodel.address

import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { Address, LegalForm, UploadFile } from "../common";

export const BillingReferenceSeparators = [
    '-',
    '/',
    '_',
    '.'
];

export enum BillingReferenceSegmentType {
    CompanyRef = 'companyRef',
    CustomerRef = 'customerRef',
    Year = 'year',
    Increment = 'increment'
}

export type BillingReferenceSegment = {
    type: BillingReferenceSegmentType;
    separator: string;
}

export type BillingReferenceSettings = {
    companyRef?: string;
    segments?: BillingReferenceSegment[];
}

export type CompanyIncrements = {
    quote: number;
    invoice: number;
}

export type CompanyQuoteSettings = {
    reference: BillingReferenceSettings;
}

export type CompanyInvoiceSettings = {
    reference: BillingReferenceSettings;
}

@CwtModel({ name: 'company' })
@CwtCrudContract({ path: 'company', searchMapper: (search) => ({ name: { regexp: search } }) })
export class Company extends CwtApiEntity {
    @CwtProp() name: string;
    @CwtProp() siren: string;
    @CwtProp() legalForm: LegalForm;
    @CwtProp() vatNumber: string;
    @CwtProp() increments: CompanyIncrements;
    @CwtProp() quoteSettings: CompanyQuoteSettings;
    @CwtProp() invoiceSettings: CompanyInvoiceSettings;
    @CwtProp({ ref: UploadFile }) logo: UploadFile;
    @CwtProp({ ref: Address }) address: Address;
    @CwtProp() vatRates: number[];
    @CwtProp() defaultVatRate: number;
}